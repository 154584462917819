<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="modelallsale">
        <div class="con">
            <p class="con_tit">表单代写
                <!-- <br />
                已购节点数{{jiedian_num}}，已选人数{{renyuan_list.length}} -->
            </p>
            <!-- <ul class="con_tit_xuan">
                <li v-for="(i,index) in biaoti_list" :class="an_shenme==index?'zise':''" @click='an_shenme=index'  :key="index">{{i}}</li>
            </ul> -->
            <div class="con_box">
                <div class="con_box_left" v-if="an_shenme==0">
                    <!--
                        check-strictly -->
                        <!-- show-checkbox
                        @check-change="xuanze_renyuan"
                        :default-checked-keys='gouxuan_key' -->
                    <el-tree
                        :data="faqi_ren_list"
                        class="tree_bumen"
                        default-expand-all
                        node-key="id"
                        ref="all_staff_tree"
                        highlight-current
                        :props="defaultProps">
                        <template  slot-scope="{ node, data }">
                            <div class="tree_bumen_box">
                                <p>
                                    <img v-if="data.type==2" @click="xuanze_renyuan(data)" :src="data.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    {{data.type==2?node.label:node.label+'('+data.renshu+')'}}
                                </p>
                            </div>
                        </template>
                    </el-tree>
                </div>
                <div class="con_box_right">
                    <ul>
                         <!-- @click="dianji_danxuan(i)" -->
                        <li v-for="(i,index) in renyuan_list" :class="Number(index)%2==0?'huise':''" :key="i.id">
                            <!-- <img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""> -->
                            <p class="touxiang">{{i.label[0]}}</p>
                            <div>
                                <p>{{i.label}}</p>
                                <!-- <p>{{i.dept_name}}</p> -->
                            </div>
                            <!--  v-if="i.id!=$jichuxinxi().user_id" -->
                            <i class="el-icon-error" @click="shanchu_ren(i)"></i>
                        </li>
                    </ul>
                </div>
            </div>
            <p class="con_foot">
                <span @click="dianji_baocun">保存</span>
            </p>
        </div>
    </div>
</template>

<script>
import { query_ent_node_num, update_form_replace_write, query_form_replace_write, query_dept_staff_tree_aim_not, update_staff } from '../../api/api.js'
// import func from '../../vue-temp/vue-editor-bridge.js';
export default {
  name: 'modelallsale',
  data () {
    return {
      faqi_ren_list: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      renyuan_list: [],
      gouxuan_key: [], // 默认勾选的节点的 key 的数组
      biaoti_list: ['按组织架构', '按角色'],
      an_shenme: '0',
      ids: [],
      type: '0',
      mianbao_list_jue: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_juese_list: [],
      qiye_juesery_list: [],
      qiye_juesery_list_xuan: [],
      quanxuan_jue: false,
      juese_sousuo: '',
      jiedian_num: '',
      all_ren: []
    }
  },
  created () {
    this.chakan()
    this.mianbao_list_jue[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  watch: {
    //   faqi_ren_list:{
    //       handler(newV,oldV){
    //           this.zhuanhua(newV)
    //       },
    //       deep:true
    //   }
  },
  mounted () {

  },
  methods: {
    jichu () {
      query_dept_staff_tree_aim_not({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          const _this = this
          console.log(date)
          function xunhuan (data) {
            if (data) {
              data.forEach(item => {
                if (item.type == 1) {
                  if (item.children != undefined) {
                    const ren = []
                    function renshu (data) {
                      data.forEach(ite => {
                        if (ite.type == 1) {
                          if (ite.children != undefined) {
                            renshu(ite.children)
                          }
                        }
                        if (ite.type == 2) {
                          ren.push('1111')
                        }
                      })
                    }
                    renshu(item.children)
                    console.log(ren.length)

                    _this.$set(item, 'renshu', ren.length)
                    xunhuan(item.children)
                  }
                }
                if (item.type == 2) {
                  _this.ids.forEach(ite => {
                    if (ite == item.id) {
                      _this.$set(item, 'zhi', true)
                      _this.zhuanhua(_this.faqi_ren_list)
                    }
                  })
                  if (item.zhi == undefined) {
                    _this.$set(item, 'zhi', false)
                  }
                }
              })
            }
          }
          xunhuan(date)
          this.faqi_ren_list = date
          this.zhuanhua(date)
          console.log(date)
        } else if (res.data.code == 10169) {}
      })
      query_ent_node_num({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.jiedian_num = date
        } else if (res.data.code == 500) {}
      })
    },
    chakan () {
      query_form_replace_write({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          const _this = this
          console.log(date)
          if (date != undefined && date != null && date.length != 0) {
            date.forEach(item => {
              _this.$set(item, 'id', item.staff_id)
            })
            this.all_ren = date
            this.ids = date.map(item => { return item.id })
          } else {
            this.all_ren = []
            this.ids = []
          }
          this.jichu()
        } else if (res.data.code == 500) {}
      })
    },
    xuanze_renyuan (data) {
      console.log(this.faqi_ren_list)
      // let dangqian_jiedian=5
      if (data.zhi) {
        if (data.id == this.$jichuxinxi().user_id) {
          this.$message({
            message: '您不可以把自己移除节点之外',
            type: 'warning'
          })
        } else {
          const list = this.faqi_ren_list
          const _this = this
          function zhuan (date) {
            if (date) {
              date.forEach(item => {
                if (item.type == 1) {
                  if (item.children != undefined && item.length != 0) {
                    zhuan(item.children)
                  }
                }
                if (item.type == 2) {
                  if (item.id == data.id) {
                    _this.$set(item, 'zhi', false)
                  }
                  for (let a = 0; a < _this.all_ren.length; a++) {
                    if (_this.all_ren[a].id == data.id) {
                      _this.all_ren.splice(a, 1)
                    }
                  }
                }
              })
            }
          }
          zhuan(list)
          this.zhuanhua(this.faqi_ren_list)
        }
      } else {
        if (this.renyuan_list.length < Number(this.jiedian_num)) {
          this.$set(data, 'zhi', true)
          this.zhuanhua(this.faqi_ren_list)
        } else {
          this.$message({
            message: '节点数量不够，联系客服进行购买',
            type: 'warning'
          })
        }
      }
    },
    // 转化
    zhuanhua (data) {
      let list = []
      const _this = this
      function zhuan (date) {
        if (date) {
          date.forEach(item => {
            if (item.type == 1) {
              if (item.children != undefined && item.length != 0) {
                zhuan(item.children)
              }
            }
            if (item.type == 2) {
              if (item.zhi) {
                list.push(item)
              }
            }
          })
        }
        if (date) {
          date.forEach(item => {
            if (item.type == 1) {
              if (item.children != undefined && item.length != 0) {
                zhuan(item.children)
              }
            }
            if (item.type == 2) {
              list.forEach(ite => {
                if (ite.id == item.id) {
                  _this.$set(item, 'zhi', true)
                }
              })
            }
          })
        }
      }
      zhuan(data)
      this.all_ren.forEach(item => {
        list.push(item)
      })
      list = this.$func.Es5duplicate(list, 'id')
      console.log(list)
      this.renyuan_list = list
    },
    shanchu_ren (i) {
      const list = this.faqi_ren_list
      const _this = this
      function zhuan (date) {
        if (date) {
          date.forEach(item => {
            if (item.type == 1) {
              if (item.children != undefined && item.length != 0) {
                zhuan(item.children)
              }
            }
            if (item.type == 2) {
              if (item.id == i.id) {
                _this.$set(item, 'zhi', false)
              }
              for (let a = 0; a < _this.all_ren.length; a++) {
                if (_this.all_ren[a].id == i.id) {
                  _this.all_ren.splice(a, 1)
                }
              }
            }
          })
        }
      }
      zhuan(list)
      _this.zhuanhua(_this.faqi_ren_list)
    },
    dianji_baocun () {
      update_form_replace_write({
        data: {
          ent_id: this.$ent_id(),
          user_ids: this.renyuan_list.length != 0 ? this.renyuan_list.map(item => item.id).join(',') : ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        } else if (res.data.code == 500) {}
      })
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modelallsale {
    height:100%;
    width:100%;
    .con{
        padding: 0.33rem;
        .con_tit{
            font-size: 0.12rem;
            color:#444;
            margin-bottom: 0.3rem;
        }
        .con_tit_xuan{
            display: flex;
            align-items: center;
            li{
                padding: 0 0.12rem;
                border-radius:0.02rem;
                color:#888888;
                border:0.01rem solid #A9A9A9;
                text-align: center;
                line-height: 0.25rem;
                font-size: 0.12rem;
                background: #fff;
                border-radius: 0.03rem;
                cursor:pointer;
                margin-right: 0.1rem;
            }
            .zise{
                background:rgb(154, 132, 219);
                color:#fff;
            }
        }
        .con_box{
            display: flex;
            align-items: center;
            .con_box_left,.con_box_right{
                width:50%;
                border:0.01rem solid #ccc;
                height:430px;
                overflow-y:auto;
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    height: 0.08rem;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .xuanze_sousuo{
                    position: relative;
                    i{
                        position: absolute;
                        top:0.11rem;
                        left: 0.67rem;
                    }
                    input{
                        height:0.3rem;
                        width:2.74rem;
                        border:0.01rem solid #ccc;
                        background:#fff;
                        padding: 0 0.33rem;
                        margin: 0 auto;
                        display: block;
                        margin-top: 0.15rem;
                        border-radius:0.02rem;
                    }
                }
                .mianbaoxie{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    margin-bottom: 0.1rem;
                    margin-left: 0.23rem;
                    margin-top:0.12rem;
                    div{
                        display: flex;
                        align-items: center;
                        cursor:pointer;
                        p{
                            font-size: 0.14rem;
                        }
                        .p2{
                            color:#F96C6C;
                        }
                        .p1{
                            color:#1A2533;
                        }
                    }
                }
                .quanxuan{
                    display: flex;
                    align-items: center;
                    margin-left: 0.24rem;
                    input{
                        -webkit-appearance: none;
                        width: 0.18rem!important;
                        height: 0.17rem!important;
                        display: inline-block;
                        margin: -0.03rem 0.05rem 0 0!important;
                        cursor: pointer;
                        vertical-align: middle;
                        background:url(../../assets/noccc.png) no-repeat;
                        &:checked {
                            background: url(../../assets/okblue.png) no-repeat;
                        }
                        outline-color:none;
                        outline-style :none;
                        outline-width:none;
                    }
                    img{
                        height:0.16rem;
                        width:0.16rem;
                        margin-right: 0.08rem;
                        cursor: pointer;
                    }
                    span{
                        color:#1A2533;
                        font-size: 0.12rem;
                    }
                }
                .nei_box{
                    width:3.45rem;
                    margin: 0 auto;
                    max-height: 2.4rem;
                    overflow:auto;
                    // overflow-y: hidden;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 0.19rem;
                        height: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    .liebiao{
                        width:3.25rem;
                        margin: 0 auto;
                        ul{
                            li{
                                display: flex;
                                height:0.3rem;
                                justify-content: space-between;
                                align-items: center;
                                color:#1A2533;
                                font-size: 0.12rem;
                                padding-left: 0.1rem;
                                cursor:pointer;
                                &:hover{
                                    background: #eee;
                                }
                                .img1{
                                    height:0.11rem;
                                    width:0.2rem;
                                }
                                input{
                                    -webkit-appearance: none;
                                    width: 0.18rem!important;
                                    height: 0.17rem!important;
                                    display: inline-block;
                                    margin: -0.03rem 0.05rem 0 0!important;
                                    cursor: pointer;
                                    vertical-align: middle;
                                    background:url(../../assets/noccc.png) no-repeat;
                                    &:checked {
                                        background: url(../../assets/okblue.png) no-repeat;
                                    }
                                    outline-color:none;
                                    outline-style :none;
                                    outline-width:none;
                                }
                                .img2{
                                    height:0.16rem;
                                    width:0.16rem;
                                    margin-right: 0.08rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .tree_bumen_box{
                    p{
                        display: flex;
                        align-items: center;
                        img{
                            height: 0.16rem;
                            width: 0.16rem;
                        }
                    }
                }
            }
            .con_box_right{
                ul{
                    li{
                        font-size: 0.12rem;
                        padding:0.1rem 0 0.07rem 0.2rem;
                        color:#444;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        margin: 0.06rem 0;
                        position: relative;
                        img{
                            height:0.14rem;
                            width:0.14rem;
                            margin-right: 0.06rem;
                        }
                        .touxiang{
                            height:0.4rem;
                            width:0.4rem;
                            line-height: 0.4rem;
                            background: #1890ff;
                            color:#fff;
                            font-size: 0.2rem;
                            text-align: center;
                            font-family: NSimSun;
                            border-radius: 50%;
                            margin-right: 0.08rem;
                        }
                        div{
                            p{
                                font-size: 0.12rem;
                                &:nth-child(1){
                                    color:#444;
                                }
                                &:nth-child(2){
                                    color:#999;
                                }
                            }
                        }
                        i{
                            position: absolute;
                            right:0.2rem;
                            font-size: 0.2rem;
                            cursor: pointer;
                            top:0.2rem;
                        }
                    }
                    .huise{
                        background: #f6f8f9;
                    }
                }
            }
        }
        .con_foot{
            display: flex;
            justify-content: center;
            margin-top: 0.1rem;
            span{
                padding: 0.08rem 0.13rem;
                border-radius: 0.04rem;
                background: rgb(154, 132, 219);
                font-size: 0.14rem;
                color:#fff;
                display: inline-block;
                cursor: pointer;
            }
        }
    }
}
</style>
